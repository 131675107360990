@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text:linear-gradient(90deg, #1B1C1D 0%, #0076BA 52.68%, #0076BA 85.30%);;   

  --gradient-bar:linear-gradient(90deg, #8a8b8b 0%, #043e5f 52.68%, #0076BA 85.30%);;
  
  --color-bg: #fcfdff;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #5f5f5f;
  --color-subtextt: #7d7c7c;

}
