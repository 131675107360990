.shop-section {
    font-family: var(--font-family);
    padding: 40px 80px;
    text-align: center;
    background-color: #ffffff;
  }
  
  .shop-title {
    font-size: 2rem;
    font-weight: 800;
    margin-bottom: 60px;
  }
  
  .shop-items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .shop-item {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    width: 30%;
  }
  
  .shop-item img {
    max-width: 100%;
    height: auto;
    margin-bottom: 15px;
  }
  
  .shop-item h3 {
    font-size: 1.1rem;
    color: #222;
    margin-bottom: 10px;
  }
  
  .shop-item p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 15px;
  }
  
  .cta-button {
    display: inline-block;
    margin-top: 30px;
    padding: 10px 20px;
    background-color: #060606;
    color: white;
    text-decoration: none;
    border-radius: 25px;
    transition: background-color 0.3s;
  }
  
  .cta-button:hover {
    background-color: #0056b3;
  }
  
  /* Responsive adjustments for mobile */
  @media (max-width: 768px) {
    .shop-section {
        padding: 40px 30px;

    }
    .shop-items {
      flex-direction: column;
      align-items: center;
    }
  
    .shop-item {
      width: 100%;
      margin-bottom: 20px;
    }
  
    .shop-title {
      font-size: 1.8rem;
    }
  }
  
  @media (max-width: 480px) {
    .shop-title {
      font-size: 1.5rem;
    }
  
    .cta-button {
      padding: 8px 16px;
      font-size: 0.9rem;
    }
  }
  