.gpt3__brand {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
  .gpt3__brand div {
    flex: 1;
    max-width: 120px; /* Establece un ancho máximo para los contenedores */
    margin: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .gpt3__brand img {
    max-width: 100%; /* Establece un ancho máximo para las imágenes */
    max-height: 100%; /* Establece un alto máximo para las imágenes */
    object-fit: contain; /* Controla cómo se ajusta la imagen en su contenedor */
  }
  
  /* Estilos para dispositivos móviles */
  @media (max-width: 768px) {
    .gpt3__brand {
      flex-direction: column; /* Muestra los logos en una columna */
      text-align: center; /* Centra el texto en dispositivos móviles */
    }
  
    .gpt3__brand div {
      margin: 1rem 0; /* Espacio entre logos en dispositivos móviles */
    }
  }
  