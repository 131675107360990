* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;}

body {
  background: var(--color-bg);
}

a {
  color: unset;
  text-decoration: none;
}

.gradient__bg {
  background: -moz-radial-gradient(circle at 3% 25%, rgb(176, 176, 253) 0%, rgba(255, 255, 255, 1) 25%);
  background: -webkit-radial-gradient(circle at 3% 25%, rgba(176, 176, 253) 0%, rgba(255, 255, 255, 1) 25%);
  background: -o-radial-gradient(circle at 3% 25%, rgba(176, 176, 253) 0%, rgba(255, 255, 255, 1) 25%);
  background: -ms-radial-gradient(circle at 3% 25%, rgba(176, 176, 253) 0%, rgba(255, 255, 255, 1) 25%);
  background: radial-gradient(circle at 3% 25%, rgba(176, 176, 253) 0%, rgba(255, 255, 255, 1) 25%);
}

.gradient__bg2 {
  background: -moz-radial-gradient(circle at 3% 50%, rgb(176, 176, 253) 0%, rgba(255, 255, 255, 1) 2%);
  background: -webkit-radial-gradient(circle at 3% 50%, rgba(176, 176, 253) 0%, rgba(255, 255, 255, 1) 25%);
  background: -o-radial-gradient(circle at 3% 50%, rgba(176, 176, 253) 0%, rgba(255, 255, 255, 1) 25%);
  background: -ms-radial-gradient(circle at 0% 50%, rgba(176, 176, 253) 0%, rgba(255, 255, 255, 1) 25%);
  background: radial-gradient(circle at 0% 50%, rgb(174, 174, 243) 0%, rgba(255, 255, 255, 1) 18%);
}

.gradient__bg3 {
  background: -moz-radial-gradient(circle at 97% 50%, rgb(179, 179, 249) 0%, rgba(255, 255, 255, 1) 25%);
  background: -webkit-radial-gradient(circle at 97% 50%, rgba(176, 176, 253) 0%, rgba(255, 255, 255, 1) 25%);
  background: -o-radial-gradient(circle at 97% 50%, rgba(176, 176, 253) 0%, rgba(255, 255, 255, 1) 25%);
  background: -ms-radial-gradient(circle at 97% 50%, rgba(176, 176, 253) 0%, rgba(255, 255, 255, 1) 25%);
  background: radial-gradient(circle at 97% 50%, rgb(192, 192, 246) 0%, rgba(255, 255, 255, 1) 25%);

  @media (prefers-color-scheme: dark) {
    body {
      background: var(--color-bg);  /* Utiliza el color de fondo oscuro cuando está en modo oscuro */
    }}
}

.whatsapp-button {
  position: fixed;
  bottom: 20px;  /* Adjust this value to control the distance from the bottom */
  left: 20px;    /* Adjust this value to control the distance from the left */
  z-index: 999;  /* Ensure the button appears above other content */
}

.whatsapp-button img {
  width: 50px;   /* Adjust the width of the icon */
  height: auto;  /* Maintain aspect ratio */
}

.gradient__text {
  background: linear-gradient(90deg, #1B1C1D 0%, #0076BA 52.68%, #0076BA 85.30%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gradient__light {
  background: linear-gradient(90deg, #fefeff 0%, #a9d9f5 52.68%, #5ebdf4 85.30%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient__bar {
  background: var(--gradient-bar);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}





@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  }  
}