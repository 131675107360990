.gpt3__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: var(--color-footer);
}

.gpt3__footer-heading {
    width: 100%;
    text-align: center;

    margin-bottom: 3rem;
}

.gpt3__footer-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 64px;
    line-height: 75px;
}

.gpt3__footer-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1rem;
    border: 2px solid #fff;
    text-align: center;
    
    /* Add rounded borders */
    border-radius: 8px;

    margin-bottom: 10rem;
    cursor: pointer;

    /* Add a transition for smooth hover effect */
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.gpt3__footer-btn p {
    font-size: 18px;
    font-family: var(--font-family);
    line-height: 21px;
    color: #fff;
    word-spacing: 2px;
}

/* Apply gray hover effect */
.gpt3__footer-btn:hover {
    background-color: #216193;
    color: #333;
    border-color: #f0f0f0;
}


.gpt3__footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;
    text-align: left;
}

.gpt3__footer-links div {
    width: auto;
    height: auto;
    margin: 1rem;
}

.gpt3__footer-links_logo {
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
}

.gpt3__footer-links_logo img {
    width: auto;
    height: auto;

    margin-bottom: 1rem;
}

.gpt3__footer-links_logo p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 15px;
    color: #fff;
}

.gpt3__footer-links_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.gpt3__footer-links_div h4 {
    font-size: 14px;
    line-height: 17px;
    font-family: var(--font-family);
    color: #fff;

    margin-bottom: 0.9rem;
}

.gpt3__footer-links_div p {
    font-size: 12px;
    line-height: 15px;
    font-family: var(--font-family);
    color: #fff;

    margin: 0.5rem 0;
    cursor: pointer;
}

.gpt3__footer-copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
}

.flipweb{
    color: #b3b2b2;

}

.gpt3__footer-copyright p {
    font-size: 14px;
    font-family: var(--font-family);
    line-height: 15px;
    color: #fff;
}

.whatsapp-buttonn {
    bottom: 20px;  /* Adjust this value to control the distance from the bottom */
    left: 20px;    /* Adjust this value to control the distance from the left */
    z-index: 999;  /* Ensure the button appears above other content */
  }
  
  .whatsapp-buttonn img {
    width: 50px;   /* Adjust the width of the icon */
    height: auto;  /* Maintain aspect ratio */
    padding: 7px;
  }

@media screen and (max-width: 850px) {
    .gpt3__footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .gpt3__footer-links div {
        margin: 1rem 0;
    }

    .gpt3__footer-btn p {
        font-size: 14px;
        line-height: 20px;
    }
}

@media screen and (max-width: 400px) {
    .gpt3__footer-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }
}
